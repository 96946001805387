
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchTable from '@/components/wizard/SearchTable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'submitted-reassessment-claim-2',
  components: {
    Datatable,
    SearchTable,
  },
  data() {
    return {
      batch: {},
      bill: {
        tranche_id: '',
        entity_id: '',
        entity_contract_id: '',
        bill_no: '',
      },
      tranches: [],
      entityInfos: [],
      contractInfos: [],
      allBillNo: [],
      billList: [],
      allBill: [
        {
          trainee_count: '',
          bill_amount: '',
          contribution_percentage: '',
          contribution_amount: '',
          net_payable: '',
        },
      ],
      status: '',
      load: false,
      loading: false,
      tolatTrainee: 0,
      tolatBillAmount: 0,
      contributionPay: 0,
      totalContributionAmount: 0,
      totalPayableAmount: 0,
      showBillList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.getAssociation();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllBillNo() {
      this.load = true;
      await ApiService.get(
        'bill/claim_2/reassessment/all_bill_no?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&entity_contract_id=' +
          this.bill.entity_contract_id
      )
        .then((response) => {
          this.allBillNo = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBillList() {
      this.load = true;
      this.tolatTrainee = 0;
      this.tolatBillAmount = 0;
      this.contributionPay = 0;
      this.totalContributionAmount = 0;
      this.totalPayableAmount = 0;
      if (this.bill.bill_no) {
        await ApiService.get(
          'bill/claim_2/reassessment/list?entity_id=' +
            this.bill.entity_id +
            '&tranche_id=' +
            this.bill.tranche_id +
            '&bill_sequence_no=' +
            this.bill.bill_no +
            '&entity_contract_id=' +
            this.bill.entity_contract_id
        )
          .then((response) => {
            if ((response.data.data[0].active_status = 2)) {
              this.status = 'Your bill is waiting for approvel.';
            } else if ((response.data.data[0].active_status = 3)) {
              this.status = 'Your bill has been Approved.';
            } else {
              this.status = 'Your bill has been Submitted.';
            }
            this.billList = response.data.data;
            this.allBill = response.data.data;
            this.allBill.forEach((el) => {
              this.tolatTrainee += ~~el.trainee_count;
              this.tolatBillAmount += ~~el.bill_amount;
              this.contributionPay = ~~el.contribution_percentage;
              this.totalContributionAmount += parseFloat(
                el.contribution_amount
              );
              this.totalPayableAmount += parseFloat(el.net_payable);
            });
            this.showBillList = true;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        this.load = false;
        Swal.fire({
          title: 'Error!',
          html: 'Please select a bill.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
  },
  setup() {},
});
